import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#F12826" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink target="_blank" href="https://www.facebook.com/sehmisushicourtenay/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sehmi Courtenay Facebook"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/sehmi-japanese-restaurant-courtenay">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Sehmi Courtenay Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.zomato.com/courtenay-bc/sehmi-japanese-restaurant-courtenay">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sehmi Courtenay Zomato"
              />
            </CFLink>
            {/*<CFLink href="https://www.instagram.com/hoshi_sushibar/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sehmi Courtenay Instagram"
              />
            </CFLink>*/}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#F12826" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink target="_blank" href="https://www.facebook.com/sehmisushicourtenay/">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Sehmi Courtenay Facebook"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.yelp.ca/biz/sehmi-japanese-restaurant-courtenay">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Sehmi Courtenay Yelp"
              />
            </CFLink>
            <CFLink target="_blank" href="https://www.zomato.com/courtenay-bc/sehmi-japanese-restaurant-courtenay">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Sehmi Courtenay Zomato"
              />
            </CFLink>
            {/*<CFLink href="https://www.instagram.com/hoshi_sushibar/?hl=en">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Sehmi Courtenay Instagram"
                hover
              />
            </CFLink>*/}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
