import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          maxHeight="667px"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          alignCenter
        >
          <CFView textCenter w="100%">
            <CFView mt="15%">
              <CFImage
                w="90%"
                src={mobileHeroText}
                alt="Sehmi Courtenay hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="95vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) 20% 0% / cover no-repeat`}
          zIndex={90}
          row
          justifyStart
          alignCenter
        >
          <CFView column justifyCenter alignStart ml="7%" mb="2%">
            <CFView column justifyCenter alignStart>
              <CFImage
                w="520px"
                src={heroText}
                alt="Sehmi Courtenay hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="30px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
